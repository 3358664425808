import { Injectable } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";
import { PagerService } from "./pagerservice";
@Injectable({
  providedIn: "root",
})
export class Util {
  constructor(private router: Router, private pagerService: PagerService) {}

  IsNotEmpty(data: any) {
    return data != null && data !== undefined && data !== "";
  }

  IsNotNull(data: any) {
    return data != null;
  }

  IsNotUndefined(data: any) {
    return data != null && data !== undefined;
  }

  IsLength(data: any) {
    return this.IsNotNull(data) && data.length !== 0;
  }

  IsNoLength(data: any) {
    return !this.IsNotNull(data) || (this.IsNotNull(data) && data.length === 0);
  }

  IsSuccess(data: any) {
    return (
      this.IsNotUndefined(data) &&
      this.IsNotUndefined(data.Success) &&
      data.Success === true
    );
  }

  ConvertLowerCase(data: any) {
    if (this.IsNotEmpty(data)) {
      data = data.toLowerCase();
    }
    return data;
  }

  pad(n: any, width: any, z: any) {
    z = z || "0";
    n = n + "";
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  pad2(data: number) {
    return (data < 10 ? "0" : "") + data;
  }

  pad3(data: number) {
    return (data < 10 ? "00" : data < 100 ? "0" : "") + data;
  }

  pad4(data: number) {
    return (
      (data < 10 ? "000" : data < 100 ? "00" : data < 1000 ? "0" : "") + data
    );
  }

  getCurrentDateToDB() {
    const dateObj = new Date();
    return this.formatDate(dateObj);
  }

  getCurrentTime() {
    const dateObj = new Date();
    const h = dateObj.getHours();
    const m = dateObj.getMinutes();
    return this.pad2(h) + ":" + this.pad2(m);
  }

  getYesterdayDateToDB() {
    const datePrevObj = new Date();
    datePrevObj.setDate(datePrevObj.getDate() - 1);
    return this.formatDate(datePrevObj);
  }

  getTime(dateObj: Date) {
    const time =
      this.pad2(dateObj.getHours()) + ":" + this.pad2(dateObj.getMinutes());
    return time;
  }

  getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  formatDate(date: Date, delimitator: any = "-") {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    month = this.pad2(parseInt(month, 10));
    day = this.pad2(parseInt(day, 10));
    return [month, day, year].join(delimitator);
  }

  formateWeeklySalesDate(date: Date) {
    const arrDay = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const arrMonth = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    month = this.pad2(parseInt(month, 10));
    day = this.pad2(parseInt(day, 10));
    return arrDay[d.getDay()] + "(" + arrMonth[d.getMonth()] + " " + day + ")";
  }

  applyFilter(
    value: string,
    lstColumnData: any,
    lstFilterData: any,
    lstMasterData: any
  ) {
    if (this.IsNotEmpty(value)) {
      lstFilterData = lstMasterData.filter((item) => {
        const lstData = lstColumnData
          .filter((x: any) => x.isSearch === true)
          .filter((column) => {
            let columnValue = item[column.Name];
            columnValue =
              this.IsNotNull(column.isAuto) &&
              column.isAuto === true &&
              this.IsNotEmpty(column.Prefix) &&
              this.IsNotEmpty(column.Padding)
                ? this.autoNumber(columnValue, column.Prefix, column.Padding)
                : columnValue;
            return (
              this.IsNotNull(column) &&
              this.IsNotEmpty(column.Name) &&
              this.IsNotNull(columnValue) &&
              columnValue.toString().toLowerCase().indexOf(value) !== -1
            );
          });
        return this.IsNotNull(lstData) && lstData.length != 0 ? true : false;
      });
    } else {
      lstFilterData = lstMasterData;
    }
    return lstFilterData;
  }

  applyUPCFilter(
    value: string,
    lstColumnData: any,
    lstFilterData: any,
    lstMasterData: any
  ) {
    if (this.IsNotEmpty(value)) {
      lstFilterData = lstMasterData.filter((item) => {
        const lstData = lstColumnData
          .filter((x: any) => x.isSearch === true)
          .filter((column) => {
            let columnValue = item[column.Name];
            columnValue =
              this.IsNotNull(column.isAuto) &&
              column.isAuto === true &&
              this.IsNotEmpty(column.Prefix) &&
              this.IsNotEmpty(column.Padding)
                ? this.autoNumber(columnValue, column.Prefix, column.Padding)
                : columnValue;

            columnValue = this.IsNotEmpty(columnValue)
              ? columnValue.replaceAll("-", "")
              : "";
            return (
              this.IsNotNull(column) &&
              this.IsNotEmpty(column.Name) &&
              this.IsNotNull(columnValue) &&
              columnValue.toString().toLowerCase().indexOf(value) !== -1
            );
          });
        return this.IsNotNull(lstData) && lstData.length != 0 ? true : false;
      });
    } else {
      lstFilterData = lstMasterData;
    }
    return lstFilterData;
  }

  applySort(
    column: string,
    lstFilterData: any,
    lstMasterData: any,
    asc = false
  ) {
    if (this.IsNotEmpty(column)) {
      lstFilterData = lstMasterData.sort((a, b) => {
        if (asc) {
          return b["" + column + ""] > a["" + column + ""] ? -1 : 1;
        } else {
          return a["" + column + ""] > b["" + column + ""] ? -1 : 1;
        }
      });
    }
    return lstFilterData;
  }

  autoNumber(value: any = "", prefix: any = "", padding: any = 4) {
    if (this.IsNotEmpty(value)) {
      if (this.IsNotEmpty(prefix)) {
        return prefix + "-" + this.pad(parseInt(value, 10), padding, 0);
      } else {
        return this.pad(parseInt(value, 10), padding, 0);
      }
    } else {
      return value;
    }
  }

  getToken() {
    let token = "";
    try {
      if (this.IsNotEmpty(localStorage.getItem("token"))) {
        token = localStorage.getItem("token");
      } else {
        localStorage.setItem("token", token);
      }
    } catch (error) {}
    return token;
  }

  setToken(token) {
    try {
      if (this.IsNotNull(token)) {
        localStorage.setItem("token", token);
      }
    } catch (error) {}
  }

  getUserDetails() {
    let userDetails;
    try {
      userDetails = {
        isAuthenticated: false,
        isSupplier: false,
        userEmailAddress: "",
        userId: "",
        userName: "",
        userRoles: "",
        supplierNo: "",
        userRoleId: "",
        userRoleName: "",
        firstName: "",
        lastName: "",
        cmUserID: "",
      };

      if (this.IsNotEmpty(localStorage.getItem("userDetails"))) {
        const userData = JSON.parse(localStorage.getItem("userDetails"));
        if (this.IsNotEmpty(userData)) {
          if (
            this.IsNotEmpty(userData.UserRoles) &&
            this.IsNotNull(userData.UserRoles.split(":"))
          ) {
            if (userData.UserRoles.split(":").length >= 1) {
              userDetails.userRoleId = userData.UserRoles.split(":")[0];
            }
            if (userData.UserRoles.split(":").length >= 2) {
              userDetails.userRoleName = userData.UserRoles.split(":")[1];
            }
          }

          if (this.IsNotEmpty(userData.IsAuthenticated)) {
            userDetails.isAuthenticated =
              parseInt(userData.IsAuthenticated, 10) === 1 ? true : false;
          }

          if (this.IsNotEmpty(userData.IsSupplier)) {
            userDetails.isSupplier =
              parseInt(userData.IsSupplier, 10) === 1 ? true : false;
          }

          if (this.IsNotEmpty(userData.SupplierNo)) {
            userDetails.supplierNo = userData.SupplierNo;
          }

          if (this.IsNotEmpty(userData.UserEmailAddress)) {
            userDetails.userEmailAddress = userData.UserEmailAddress;
          }

          if (this.IsNotEmpty(userData.UserID)) {
            userDetails.userId = userData.UserID;
          }

          if (this.IsNotEmpty(userData.UserName)) {
            userDetails.userName = userData.UserName;
          }

          if (this.IsNotEmpty(userData.FirstName)) {
            userDetails.firstName = userData.FirstName;
          }

          if (this.IsNotEmpty(userData.LastName)) {
            userDetails.lastName = userData.LastName;
          }

          if (this.IsNotEmpty(userData.UserRoles)) {
            userDetails.userRoles = userData.UserRoles;
          }

          //userData.CMUserID = "[{\"CMUserID\": 37},{\"CMUserID\": 36}]";

          if (this.IsNotEmpty(userData.CMUserID)) {
            const lstCMUserID = JSON.parse(userData.CMUserID);
            userDetails.cmUserID = this.IsLength(lstCMUserID)
              ? lstCMUserID.map((x: any) => x.CMUserID)
              : [];
          }
        }
      }
    } catch (error) {}
    return userDetails;
  }

  setlocation(location) {
    try {
      if (this.IsNotEmpty(location) && this.IsNotEmpty(location.origin)) {
        localStorage.setItem("siteLocation", location.origin);
      }
    } catch (error) {}
  }

  getlocation() {
    let siteLocation = "http://localhost:4202";
    try {
      if (this.IsNotEmpty(localStorage.getItem("siteLocation"))) {
        siteLocation = localStorage.getItem("siteLocation");
      } else {
        localStorage.setItem("siteLocation", siteLocation);
      }
    } catch (error) {}
    return siteLocation;
  }

  isOffShore() {
    let isOffShore = false;
    const siteLocation = this.getlocation();
    try {
      if (
        this.IsNotEmpty(siteLocation) &&
        (siteLocation.includes("item-management-api") ||
          siteLocation.includes("localhost"))
      ) {
        isOffShore = true;
      }
    } catch (error) {}
    return isOffShore;
  }

  isDev() {
    let isDev = false;
    const siteLocation = this.getlocation();
    try {
      let getDomain = siteLocation;
      if (siteLocation.indexOf("http") >= 0) {
        getDomain = siteLocation.split("//")[1];
      }
      if (
        this.IsNotEmpty(getDomain) &&
        (getDomain.toLowerCase() == environment.DevSiteURL.toLowerCase() ||
          getDomain.toLowerCase().indexOf("dev") >= 0)
      ) {
        isDev = true;
      }
    } catch (error) {}
    return isDev;
  }

  isUAT() {
    let isUAT = false;
    const siteLocation = this.getlocation();
    try {
      let getDomain = siteLocation;
      if (siteLocation.indexOf("http") >= 0) {
        getDomain = siteLocation.split("//")[1];
      }
      if (
        this.IsNotEmpty(getDomain) &&
        (getDomain.toLowerCase() == environment.UATSiteURL.toLowerCase() ||
          getDomain.toLowerCase().indexOf("dev") >= 0)
      ) {
        isUAT = true;
      }
    } catch (error) {}
    return isUAT;
  }

  isProd() {
    let isProd = false;
    const siteLocation = this.getlocation();
    try {
      let getDomain = siteLocation;
      if (siteLocation.indexOf("http") >= 0) {
        getDomain = siteLocation.split("//")[1];
      }
      if (
        this.IsNotEmpty(getDomain) &&
        getDomain.toLowerCase() == environment.ProdSiteURL.toLowerCase()
      ) {
        isProd = true;
      }
    } catch (error) {}
    return isProd;
  }

  setUserDetails(response: any) {
    try {
      if (this.IsNotEmpty(response)) {
        const [userDetails] = response;
        if (this.IsNotEmpty(userDetails)) {
          localStorage.setItem("userDetails", JSON.stringify(userDetails));
        }
      }
    } catch (error) {}
  }

  isAuthenticated() {
    let isAuthenticated = false;
    const userDetails = this.getUserDetails();
    try {
      if (this.IsNotNull(userDetails)) {
        isAuthenticated = userDetails.isAuthenticated;
      }
    } catch (error) {}
    return true;
  }

  isSupplier() {
    let isSupplier = false;
    const userDetails = this.getUserDetails();
    try {
      if (this.IsNotNull(userDetails)) {
        isSupplier = userDetails.isSupplier;
      }
    } catch (error) {}
    return isSupplier;
  }

  userEmailAddress() {
    let userEmailAddress = "";
    const userDetails = this.getUserDetails();
    try {
      if (this.IsNotNull(userDetails)) {
        userEmailAddress = userDetails.userEmailAddress;
      }
    } catch (error) {}
    return userEmailAddress;
  }

  userId() {
    let userId = "";
    const userDetails = this.getUserDetails();
    try {
      if (this.IsNotNull(userDetails)) {
        userId = userDetails.userId;
      }
    } catch (error) {}
    return userId;
  }

  userName() {
    let userName = "";
    const userDetails = this.getUserDetails();
    try {
      if (this.IsNotNull(userDetails)) {
        userName = userDetails.userName;
      }
    } catch (error) {}
    return userName;
  }

  userShortName() {
    let userShortName = "";
    const userDetails = this.getUserDetails();
    try {
      if (this.IsNotNull(userDetails) && userDetails.userName) {
        let matches = userDetails.userName
          ? userDetails.userName.match(/\b(\w)/g)
          : [""];
        if (matches == null || matches == "") {
          matches = userDetails.userName
            ? userDetails.userName.match(/\b(\w)/g)
            : [""];
        }
        userShortName = this.IsLength(matches)
          ? matches.length === 1
            ? userDetails.userName.substring(0, 2)
            : matches.join("")
          : "";

        if (this.IsNotEmpty(userShortName)) {
          userShortName = userShortName.toUpperCase();
        }
      }
    } catch (error) {}
    return userShortName;
  }

  shortName(fullName) {
    let shortName = "";
    try {
      if (this.IsNotEmpty(fullName)) {
        let matches = this.IsNotEmpty(fullName)
          ? fullName.match(/\b(\w)/g)
          : [""];
        if (matches == null || matches == "") {
          matches = this.IsNotEmpty(fullName)
            ? fullName.match(/\b(\w)/g)
            : [""];
        }
        shortName = matches && matches.length !== 0 ? matches.join("") : "";
      }
    } catch (error) {}
    return shortName;
  }

  firstName() {
    let firstName = "";
    const userDetails = this.getUserDetails();
    try {
      if (this.IsNotNull(userDetails)) {
        firstName = userDetails.firstName;
      }
    } catch (error) {}
    return firstName;
  }

  lastName() {
    let lastName = "";
    const userDetails = this.getUserDetails();
    try {
      if (this.IsNotNull(userDetails)) {
        lastName = userDetails.lastName;
      }
    } catch (error) {}
    return lastName;
  }

  supplierNo() {
    let supplierNo = "";
    const userDetails = this.getUserDetails();
    try {
      if (this.IsNotNull(userDetails)) {
        supplierNo = userDetails.supplierNo;
      }
    } catch (error) {}
    return supplierNo;
  }

  userRoleId() {
    let userRoleId = "";
    const userDetails = this.getUserDetails();
    try {
      if (this.IsNotNull(userDetails)) {
        userRoleId = userDetails.userRoleId;
      }
    } catch (error) {}
    return userRoleId;
  }

  userRoleName() {
    let userRoleName = "";
    const userDetails = this.getUserDetails();
    try {
      if (this.IsNotNull(userDetails)) {
        userRoleName = userDetails.userRoleName;
      }
    } catch (error) {}
    return userRoleName;
  }

  cmUserID() {
    let cmUserID = [];
    const userDetails = this.getUserDetails();
    try {
      if (this.IsNotNull(userDetails) && this.IsLength(userDetails.cmUserID)) {
        cmUserID = userDetails.cmUserID;
      }
    } catch (error) {}
    return cmUserID;
  }

  isNewEditRequest() {
    let isNewEditRequest = false;
    const newEditRequest = localStorage.getItem("isNewEditRequest");
    try {
      if (this.IsNotEmpty(newEditRequest)) {
        isNewEditRequest = true;
      }
    } catch (error) {}
    return isNewEditRequest;
  }

  newEditRequestId() {
    let newEditRequestId = "";
    const newEditRequest = localStorage.getItem("isNewEditRequest");
    try {
      if (this.IsNotEmpty(newEditRequest)) {
        newEditRequestId = localStorage.getItem("isNewEditRequest");
      }
    } catch (error) {}
    return newEditRequestId;
  }

  checkDigitUPC(UPCCode) {
    // Check if only digits
    const ValidChars = "0123456789";
    for (let i = 0; i < UPCCode.length; i++) {
      const digit = UPCCode.charAt(i);
      if (ValidChars.indexOf(digit) == -1) {
        return false;
      }
    }

    if (UPCCode.length != 12) {
      return false;
    }

    let originalCheck = UPCCode.substring(UPCCode.length - 1);
    // Add even numbers together
    let even =
      Number(UPCCode.charAt(1)) +
      Number(UPCCode.charAt(3)) +
      Number(UPCCode.charAt(5)) +
      Number(UPCCode.charAt(7)) +
      Number(UPCCode.charAt(9)) +
      Number(UPCCode.charAt(11));
    // Multiply this result by 3
    even *= 3;

    // Add odd numbers together
    let odd =
      Number(UPCCode.charAt(0)) +
      Number(UPCCode.charAt(2)) +
      Number(UPCCode.charAt(4)) +
      Number(UPCCode.charAt(6)) +
      Number(UPCCode.charAt(8)) +
      Number(UPCCode.charAt(10));

    // Add two totals together
    let total = even + odd;

    // Calculate the checksum
    // Divide total by 10 and store the remainder
    let checksum = total % 10;
    // If result is not 0 then take away 10
    if (checksum != 0) {
      checksum = 10 - checksum;
    }

    // Return the result
    if (checksum != originalCheck) {
      return false;
    }

    return true;
  }

  getBannerList(banners: any = "") {
    const bannerList = [];
    if (this.IsNotEmpty(banners) && banners.split(",").length != 0) {
      for (const banner of banners.split(",")) {
        if (banner.split(":").length != 0) {
          const bannerId = banner.split(":")[0];
          if (banner.split(":").length >= 2) {
            const bannerName = banner.split(":")[1];
            bannerList.push({
              BannerID: parseInt(bannerId, 10),
              BannerName: bannerName,
            });
          }
        }
      }
    }
    return bannerList;
  }

  requestBanner(bannerNames: any = "", bannerId: any = "") {
    let isRequestBanner = false;
    if (this.IsNotEmpty(bannerNames.toString()) && this.IsNotEmpty(bannerId)) {
      let lstBannerList = this.getBannerList(bannerNames);
      if (this.IsLength(lstBannerList)) {
        let objBanner = lstBannerList.find(
          (x: any) => x.BannerID.toString() == bannerId.toString()
        );
        if (this.IsNotEmpty(objBanner)) {
          isRequestBanner = true;
        }
      }
    }
    return isRequestBanner;
  }

  unique(inputArr: any) {
    var arr = [];
    if (this.IsNotNull(inputArr) && inputArr.length !== 0) {
      for (var i = 0; i < inputArr.length; i++) {
        if (!this.contains(arr, inputArr[i])) {
          arr.push(inputArr[i]);
        }
      }
    }
    return arr;
  }

  contains(masterArry: any, inputArr: any) {
    if (
      this.IsNotNull(masterArry) &&
      masterArry.length !== 0 &&
      this.IsNotNull(inputArr)
    ) {
      for (var i = 0; i < masterArry.length; i++) {
        if (masterArry[i] === inputArr) {
          return true;
        }
      }
    }
    return false;
  }

  isSessionExist() {
    let isExist = false;
    const userDetails = this.getUserDetails();
    isExist =
      this.IsNotNull(userDetails) &&
      this.IsNotEmpty(userDetails.userId) &&
      userDetails.userId !== "0"
        ? true
        : false;
    return isExist;
  }

  validateSession() {
    if (!this.isSessionExist()) {
      localStorage.clear();
      this.router.navigate(["/login"]);
    }
  }

  setPager(lstMasterData: any, pager: any, page: any = 1, pageSize: any = 5) {
    let pagerData = null;
    if (page < 1 || (this.IsNotNull(pager) && page > pager.totalPages)) {
      return;
    }
    if (this.IsLength(lstMasterData)) {
      pagerData = this.pagerService.getPager(
        lstMasterData.length,
        page,
        pageSize
      );
    }
    return pagerData;
  }

  applyPagination(
    lstMasterData: any,
    pager: any,
    page: any = 1,
    pageSize: any = 5
  ) {
    if (page < 1 || (this.IsNotNull(pager) && page > pager.totalPages)) {
      return;
    }
    if (!this.IsNotNull(lstMasterData)) {
      lstMasterData = [];
    }
    return this.IsLength(lstMasterData)
      ? lstMasterData.slice(pager.startIndex, pager.endIndex + 1)
      : lstMasterData;
  }

  IsNegative(data: any) {
    return this.IsNotEmpty(data) && !isNaN(data) && data < 0 ? true : false;
  }

  isRetailInfoExists(merchandising) {
    let isExists = false;
    if (this.IsNotEmpty(merchandising)) {
      if (
        this.IsNotEmpty(merchandising.AnchorUpc) &&
        merchandising.AnchorUpc.toString() !== "0"
      ) {
        isExists = true;
      } else if (
        this.IsNotEmpty(merchandising.BannerPrice) &&
        this.IsLength(JSON.parse(merchandising.BannerPrice))
      ) {
        const bannerPrice = JSON.parse(merchandising.BannerPrice).find(
          (x: any) => !this.IsNotEmpty(x.Retail) || x.Retail.toString() === "0"
        );
        isExists = !this.IsNotEmpty(bannerPrice) ? true : false;
      } else if (
        this.IsNotEmpty(merchandising.IsBlankRetail) &&
        merchandising.IsBlankRetail === 0
      ) {
        isExists = true;
      }
    }
    return isExists;
  }

  removeDuplicateArray(arr: any = []) {
    let result = [];
    if (this.IsLength(arr)) {
      result = arr.filter(
        (thing, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              JSON.stringify(t).toLowerCase() ===
              JSON.stringify(thing).toLowerCase()
          )
      );
    }
    return result;
  }

  singleQuotesReplace(data) {
    return this.IsNotEmpty(data) ? data.toString().replaceAll("'", "''") : "";
  }

  isWarehouseDetailsExist(data: any) {
    let returnData = false;
    if (this.IsNotEmpty(data)) {
      const lstWarehouseDetails = data.split(",");
      if (this.IsLength(lstWarehouseDetails)) {
        const WarehouseDetails = lstWarehouseDetails.find(
          (x: any) =>
            this.IsLength(x.split(":")) &&
            this.IsNotEmpty(x.split(":")[0]) &&
            x.split(":")[0] != "0"
        );

        if (this.IsNotEmpty(WarehouseDetails)) {
          returnData = true;
        }
      }
    }
    return returnData;
  }
}
