// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  API: "",
  //OffShoreAPI:'https://us-central1-item-management-api-316713.cloudfunctions.net/',
  OffShoreAPI: "https://us-west2-new-item-billing.cloudfunctions.net/",
  devShoreAPI: "https://us-west2-new-item-billing.cloudfunctions.net/",
  //prodShoreAPI: "https://us-west2-new-item-billing.cloudfunctions.net/",
  prodShoreAPI:
    "https://us-west2-new-item-billing-production.cloudfunctions.net/",
  //redirectURLSSO:'https://desktop.pingone.com/savemart/url?source=application&url=https%3A%2F%2Fsso.connect.pingidentity.com%2Fsso%2Fsp%2Finitsso%3Fsaasid%3D489c5d8f-6cc5-4ce9-9848-ce184392e50a%26idpid%3Ditem-management-api-316713.uc.r.appspot.com&title=IDP%20Connection&applicationtype=APPLICATION_DEFAULT&saasid=489c5d8f-6cc5-4ce9-9848-ce184392e50a&newDock=true',
  redirectURLSSODev:
    "https://desktop.pingone.com/savemart/url?source=application&url=https%3A%2F%2Fsso.connect.pingidentity.com%2Fsso%2Fsp%2Finitsso%3Fsaasid%3Dcca35545-aebc-4496-9ad0-6d8b94affeff%26idpid%3Dnewitem-dev2.savemart.com&title=IDP%20Connection&applicationtype=APPLICATION_DEFAULT&saasid=cca35545-aebc-4496-9ad0-6d8b94affeff&newDock=true",
  redirectURLSSOProd:
    "https://desktop.pingone.com/savemart/url?source=application&url=https%3A%2F%2Fsso.connect.pingidentity.com%2Fsso%2Fsp%2Finitsso%3Fsaasid%3Dcca35545-aebc-4496-9ad0-6d8b94affeff%26idpid%3Dnewitem.savemart.com&title=IDP%20Connection&applicationtype=APPLICATION_DEFAULT&saasid=cca35545-aebc-4496-9ad0-6d8b94affeff&newDock=true",
  ProdSiteURL: "newitem.savemart.com",
  DevSiteURL: "newitem-dev2.savemart.com",
  UATSiteURL: "newitem-dev2.savemart.com",
  OffshoreSiteURL: "newitem-dev2.savemart.com",

  authenticatePing: "AuthenticateWithPing",
  getMasterValues: "getMasterValues",
  verifyLogin: "VerifyLogin",
  getRequestDetails: "Get_Read_RequestDetails",
  getAllRequestDetails: "GetAllRequestDetails",
  getAllUsers: "ReadUsers",
  getAllRoles: "ReadAllRoles",
  readItemDetails: "ReadItemDetails",
  readItemDimensions: "ReadItemDimensions",
  readMerchandisingInfo: "ReadMerchandisingInfo",
  readVendoreStoreMappingDetails: "ReadVendoreStoreMappingDetails",
  readScaleItemDetails: "ReadScaleItemDetails",
  registerSupplier: "RegisterSupplier",
  createUpdateRequestDeatils: "CreateUpdateRequestDeatils",
  createUpdateItemDetails: "createUpdateItemDetails",
  createUpdateVendorStoreMappings: "CreateUpdateVendorStoreMappings",
  createUpdateItemDimensions: "CreateUpdateItemDimensions",
  createUpdateProcessApproval: "CreateUpdateProcessApproval",
  createUpdateBannerMerchandisingInfo: "CreateUpdateBannerMerchandisingInfo",
  createUpdateScaleItemDetails: "CreateUpdateScaleItemDetails",
  validateItemUPC: "ValidateItemUPC",
  editSupplier: "EditSupplier",
  savePicture: "savepicture",
  saveImage: "SaveImage",
  createUpdateUsers: "createUpdateUsers",
  AssignRoles: "AssignRoles",
  DeleteAllDetails: "DeleteAllTableDetails",
  validateAnchorUPC: "validateAnchorUPC",
  updateAnchorUPC: "updateAnchorUPC",
  authenticateVendor: "AuthenticateVendor",
  getUATMasterValues: "getMasterValues",
  MapUsersToRoles: "MapUsersToRoles",
  ResetSupplierPassword: "ResetSupplierPassword",
  getRequestDetailsInJSON: "getRequestDetailsInJSON",
  updateItemImageComments: "updateItemImageComments",
  UpdateHQRequestStatus: "UpdateHQRequestStatus",
  DeleteAllTableDetails: "DeleteAllTableDetails",
  TransferRequest: "TransferRequest",
  GetCategoryItemDetails: "GetCategoryItemDetails",
  createUpdateItemDetailsNew: "createUpdateItemDetails-New",
  //createUpdateItemDetailsNew: "createUpdateItemDetails-New-1",
};
